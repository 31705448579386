import { BaseElement, html, css } from 'Elements';
import App from 'App';

class LayoutSelector extends BaseElement {
  static get properties() {
    return {
      layout: { type: String },
    }
  }

  static get styles() {
    return css`
      :host {
        display: block;
        color:gray;
        margin-right:10px;
      }

      sl-menu {
        border:0px;
      }

      sl-menu-item::part(base) {
        line-height:25px;
      }

      sl-menu-item::part(label) {
        font-size:0.85em;
      }

      m-icon {
        font-size: 25px;
      }

      @media print {
        :host {
          display:none;
        }
      }
    `
  }
  
  static get translations() {
    return [
      super.translations,
      {
        english:{
          translation: {
            center:'Centered',
            large:'Large',
          }
        },
        french:{
          translation: {
            center:'Centré',
            large:'Large',
          }
        }
      }
    ]
  }

  connectedCallback() {
    super.connectedCallback();
    this.layout = localStorage.getItem(App.config.localKeys.layout) || 'center';
    this.firstTime = true;
    this._updateLayout(this.layout);
  }

  sendEvent() {
    const customEvent = new CustomEvent('layout-change', {
      bubbles: true,
      composed: true,
      detail: { layout: this.layout },
    });
    window.dispatchEvent(customEvent);
    this.parentNode.parentNode.hide();
  }

  _updateLayout(layout, ev) {
    if (ev) ev.stopPropagation();
    this.layout = layout;

    localStorage.setItem(App.config.localKeys.layout, layout);

    if (this.layout === 'center') {
      if (!this.firstTime) document.documentElement.classList.add('animated');
      document.documentElement.classList.remove('layout-large');
    } else {
      document.documentElement.classList.add('layout-large', this.firstTime ? undefined : 'animated');
    }

    this.firstTime = false;

    App.config.layout = this.layout;
    this.sendEvent();

    const dropdown = this.shadowRoot.querySelector('sl-dropdown');
    if (dropdown) {
      dropdown.hide();
    }

    setTimeout(() => {
      document.documentElement.classList.remove('animated');
    }, 400);

  }

  render() {
    return html`
      <sl-menu size="small">
        <sl-menu-item size="small" class="menu_item" type="checkbox" ?checked="${this.layout === 'center'}" @click="${(e) => this._updateLayout('center', e)}">
          <m-icon slot="suffix" name="width_wide"></m-icon>
          ${this._tl('center')}
        </sl-menu-item>
        <sl-menu-item size="small" class="menu_item" type="checkbox" ?checked="${this.layout === 'large'}" @click="${(e) => this._updateLayout('large', e)}">
          <m-icon slot="suffix" name="fit_width"></m-icon>
          ${this._tl('large')}
        </sl-menu-item>
      </sl-menu>
    `;
  }
}

customElements.define('layout-selector', LayoutSelector);